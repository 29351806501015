<template>
  <v-card min-width="400" height="100%">
    <v-card-title class="text-h5 ap-orange ap-white--text">
      Entity Form Simulator
    </v-card-title>

    <v-card-text class="pt-5">
      <div>
        <v-select
          v-model="json.inputType"
          :items="entities"
          label="Entity"
          outlined
          dense
          shaped
          hide-details
        />

        <v-btn-toggle
          v-model="formType"
          class="d-flex"
          tile
          @change="onFormTypeChange"
        >
          <v-btn height="40" value="new" width="50%"> New </v-btn>
          <v-btn height="40" value="edit" width="50%"> Edit </v-btn>
        </v-btn-toggle>
      </div>

      <v-jsoneditor
        v-if="json.inputQuery"
        v-model="json.inputQuery"
        :plus="false"
        height="400px"
        :options="{
          mode: 'code',
          onChangeText: onFormChanged,
        }"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VJsoneditor from 'v-jsoneditor'

export default {
  components: {
    VJsoneditor,
  },
  data() {
    return {
      formType: null,
      entities: ['contact'],
      json: {
        inputType: 'contact',
      },
    }
  },
  created() {
    window.addEventListener(
      'message',
      (event) => {
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data)

          if (data.action === 'updateForm') {
            this.json = {
              ...this.json,
              inputQuery: { ...data.fields },
            }
          }
        }
      },
      false
    )
  },
  methods: {
    onFormTypeChange() {
      if (this.formType === 'new') {
        this.json = {
          ...this.json,
          inputQuery: {
            address1_line1: '',
            address1_line2: '',
            address1_line3: '',
            address1_city: '',
            address1_stateorprovince: '',
            address1_postalcode: '',
            address1_country: '',
          },
        }
      } else {
        this.json = {
          ...this.json,
          inputQuery: {
            address1_line1: 'address_1',
            address1_line2: 'address_2',
            address1_line3: 'address_3',
            address1_city: 'city',
            address1_stateorprovince: 'state',
            address1_postalcode: 'postal_code',
            address1_country: 'country',
          },
        }
      }

      window.postMessage(
        {
          type: 'ApFormChange',
          data: this.json,
        },
        '*'
      )
    },
    onFormChanged(jsonText) {
      try {
        JSON.parse(jsonText)

        window.postMessage(
          {
            type: 'ApFormChange',
            data: this.json,
          },
          '*'
        )
      } catch {
        // invalid json
      }
    },
  },
}
</script>
